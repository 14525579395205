@import '~bootstrap/scss/bootstrap';
@import './vars.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');

body {
  font-size: 14px;
  margin: 0;
  height:100vh;
  font-family: 'Roboto';
  color: rgba(0, 0, 0, 0.76);
  max-width: 100vw;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  margin-top: 1em;
  margin-bottom: 0.5em;
}

label {
  text-transform: uppercase;
  color: rgb(163, 163, 163);
  font-size: 0.8rem;
  // letter-spacing: 0.5px;
}

a {
  text-decoration: none;
  // color: rgba(0,0,0,0.76) ;

  // background-image: linear-gradient(120deg, rgb(255, 238, 0) 0%, #ffd000 100%);
  // background-repeat: no-repeat;
  // background-size: 100% 0.3em;
  // background-position: 0 88%;
  // transition: background-size 0.25s ease-in;
  // &:hover {
  //   background-size: 100% 88%;
  //   text-decoration: none;
  //   color: black;
  // }
  &:hover {
    text-decoration: none;
  }
  // font-weight:bold;
  color: $link;
}
