$darkModeBackground: #010f22;
$link: #1677ff;
$darkModeText: #878294;
$darkModeMuted: #4d4957;
$highlight: #2cc4fc;

$lightModeText: rgba(0, 0, 0, 0.76);
$lightModeMuted: #878294;

$mobileWidthBreakpoint: 475px;
$tabletWidthBreakpoint: 800px;
