.Footer {
  color: rgba(255, 255, 255, 0.623);

  a {
    display: flex;
    flex-direction: row;
    color: rgba(255, 255, 255, 0.623);
    background: none !important;
    align-items: center;
    font-weight: normal;

    &:hover {
      color: rgba(255, 255, 255, 0.623);
    }

    img {
      opacity: 0.7;
      height: 1em;
      width: 1em;
      margin-left: 0.2em;
    }
  }

  b {
    color: white;
  }

  ul {
    list-style-type: none;
    margin-inline-start: 0;
    padding-inline-start: 0;

    li {
      margin-bottom: 0.3em;
    }
  }

  h5 {
    color: white;
    margin-bottom: 1em;
  }

  background-image: linear-gradient(0, #010f22 0%, #041835 100%);
  width: 100%;
  padding: 2em 0;

  .Logo {
    width: 40px;
    margin: 1rem 0;
  }
}
