@import '../../vars.scss';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;1,300;1,400;1,600&display=swap');

.Admin {
  a {
    background-image: none;
    color: $darkModeText;
    cursor: pointer;
    font-weight: normal;
  }

  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;

  .SidePanel {
    display: flex;
    flex-direction: column;
    min-width: 300px;
    background-color: $darkModeBackground;
    color: $darkModeText;

    padding: 2rem 4rem;

    .Logo {
      width: 40px;
      margin-right: 1em;
      opacity: 0.46;
    }

    .SideCategory {
      display: flex;
      // border-bottom: 1px solid $darkModeMuted;
      padding: 1rem 0;
      align-items: center;
      font-size: 1.2rem;
      // font-weight:600;

      a {
        color: $darkModeText;
      }

      &:last-child {
        border-bottom: none;
      }

      .SideIcon {
        margin-right: 2em;
      }

      &.Selected a {
        color: $highlight;
      }
    }

    border-right: 1px solid $darkModeMuted;
  }

  .ContentPanel {
    display: block;
    padding: 2rem;
    width: 100vw;
    background-color: white;

    table {
      td {
        vertical-align: middle;
      }
      button {
        margin-left: 0.5em;
        &:first-child {
          margin: 0;
        }
      }
    }
  }
}

.BlogPostRow {
  display: flex;
  flex-direction: row;
  align-items: center;

  div {
    margin-right: 2em;
  }
}

.Modal {
  min-width: 100px;
  min-height: 100px;
  background-color: red;
  position: fixed;
  display: flex;
  justify-self: center;
  align-self: center;
}

.react-datepicker-popper {
  z-index: 2 !important;
}
