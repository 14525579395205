.SponsorCarousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 20px;

  .Carousel {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .Sponsor {
      border: 1px lightgrey solid;
      width: 120px;
      height: 120px;

      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;

      &.Major {
        width: 180px;
        height: 180px;
      }

      margin-right: 5em;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
