@import '../vars.scss';

.Testimonials {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 4em;

  .Image {
    height: 600px;
    width:100%;

    img {
      width:100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .TextArea {
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    height:100%;
    // background-color:blue;


    h2 {
      margin:0;
    }
  
    .QuoteArea {
      height:100%;
      display:flex;
      flex-direction: column;
      justify-content: space-between;

      blockquote {
        padding: 4em 4em 1em 4em;
        line-height: 1.45;
        position: relative;
  
      }
      
      figcaption {
        text-align: right;
      }
      
      blockquote:before {
        font-family:'Roboto';
        font-weight: bold;
        display: block;
        padding-left: 15px;
        content: "\"";
        font-size: 150px;
        position: absolute;
        left: -20px;
        top: -60px;
        opacity:0.2;
      }
      
      .ControlBar {
        display:flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
  
        .ControlButton {
          padding: 0.5em;
          color: $lightModeText;
          border-radius: 3px;
          cursor: pointer;
  
          &:hover {
            background-color: #eee;
          }
        }
  
        .ControlNodeContainer {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: center;
          
          .ControlNode {
            border-radius: 100px;
            border: 1px solid #333;
            height:12px;
            width: 12px;
            margin-right: 1em;
    
            &:last-child {
              margin-right: 0;
            }
    
            &.selected {
              background-color: #333;
            } 
          }
        }
      }
    }
  }
}

@media (max-width: $tabletWidthBreakpoint) {
  .Testimonials {
    grid-template-columns: 1fr;

    .TextArea {
      h2 {
        margin: 1em 0;
      }
      .QuoteArea {
        flex-direction: column-reverse;
  
        blockquote {
          padding: 3em 2em 0 2em;
          pointer-events: none;
        }
  
        .ControlBar {
          margin-bottom: 3em;
        }
      }
    }
  }
  .Image {
    margin-bottom: 1em;
    max-height:300px;

    img {
      object-position: top;
    }
  }
}

.Quote {
  height:100%;
  display:flex;
  flex-direction:column;
  justify-content: center;
}