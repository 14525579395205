@import '../vars.scss';

.CardDeck {
  max-width: 100%;

  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  row-gap: 5em;
  column-gap: 3em;
  grid-auto-flow: row;
}

.NewsCard {
  display: grid;
  grid-template-rows: max-content max-content;

  &.Feature {
    grid-column: 1 / span 3;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 5em;

    img {
      height: 100%;
      object-fit: cover;
      margin-bottom: 0;
    }

    .CardImage {
      max-height: 400px;
    }

    .CardBody {
      display: flex;
      flex-direction: column;
      flex-shrink: 1;

      h1 {
        margin-top: 0.5em;
      }

      p {
        margin-bottom: 2em;
      }
    }
  }

  &.Hidden {
    opacity: 0.5;
  }

  img {
    border-radius: 3px;
    height: 200px;
    object-fit: cover;
    width: 100%;
    margin-bottom: 1.2em;
  }

  .CardBody {
    h4 {
      margin-top: 0;
    }

    p {
      color: rgb(177, 177, 177);
      margin-bottom: 1em;
    }
  }
}

@media (max-width: $tabletWidthBreakpoint) {
  .CardDeck {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .NewsCard {
    &.Feature {
      grid-column: 1 / span 2;
      grid-template-columns: 1fr;

      .CardBody {
        margin-top: 2em;

        h1 {
          margin-top: 0;
          font-size: 1.8em;
        }
      }
    }
  }
}

@media (max-width: $mobileWidthBreakpoint) {
  .CardDeck {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .NewsCard {
    &.Feature {
      grid-column: 1;
    } // grid-area: span 1 / span 12;
  }
}

.CardDetails {
  margin-bottom: 0.7em;
  color: rgb(177, 177, 177);
}
