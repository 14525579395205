.Page {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 50px 0;
  padding-bottom: 70px;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  min-height:70vh;
}
