@import '../vars.scss';

.Section {
  padding: 15vh 0;

  &.MailingList {
    padding: 5vh 0;
  }

  &.Split {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 100px;
    row-gap: 2em;

    &.GetStarted {
      grid-template-columns: auto max-content;
    }

    .SectionHalf {
      &.GetStarted {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      h2 {
        margin-top:0;
        margin-bottom:1em;
      }
    }
  }
}

@media (max-width: $tabletWidthBreakpoint) {
  .Section {
    &.Split {
      grid-template-columns: 1fr;
      &.GetStarted {
        grid-template-columns: 1fr;
      }
    }
  }
}

.SplashImage {
  z-index:2000;
  max-width: 1300px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  img {
    margin-top:-160px;
  }
  p {
    margin: 2em 0;
  }
}

.SplashCTA {
  // position: absolute;
  top: 0;
  left: 0;
  max-width: 60%;
}

@media (max-width: $tabletWidthBreakpoint) {
  .SplashImage {
    img {
      margin-top:0;
    }
  }
  .SplashCTA {
    max-width: 100%;
  }
}

ol {
  li {
    padding-left: 2em;
    margin-bottom:0.5em;

    &:last-child {
      margin-bottom: 0;
    }
  }
}


// .Home {
//   display:grid;
//   column-gap: 140px;
//   row-gap: 100px;

//   grid-template-rows: max-content max-content;
// }

// .Content {
//   min-width:200px;
// }

// .LinkGrid {
//   display:grid;
//   column-gap: 20px;
//   row-gap: 5px;
//   grid-template-columns: max-content max-content;
//   grid-template-rows: max-content max-content;

//   .Cell {

//   }
// }

// .FacebookFeed {
//   display: grid;
//   grid-template-columns: repeat(3,minmax(0, 1fr));

//   grid-auto-flow: row;
//   grid-gap:2em;

//   div {
//     width:100%;
//     // display: flex;
//     // justify-content:center;
//     * {
//       width:100% !important;
//     }
//   }
// }

// @media (max-width: $mobileWidthBreakpoint) {
//   .FacebookFeed {
//     grid-template-columns: repeat(1,minmax(0, 1fr));
//   }
// }

