@import '../vars.scss';

.NavContainer {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
  max-height:100px;
  width: 100%;
  box-shadow: 0px 10px 10px -3px rgba(0, 0, 0, 0.03);

  @media (max-width: $mobileWidthBreakpoint) {
    .NavSection {
      .NavChild {
        &.SiteTitle {
          display: none;
        }  

        .Logo {
          max-height:3em;
        }
      }
      
    }
  }

  &.AdminBar {
    background-color: $darkModeBackground;
    color: $darkModeText;
  }
}

.Nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;

  &.AdminBar {
    position: static;
    top: 0;
    padding: 10px 20px;

    a {
      color: white;
    }
  }

  a {
    color: $lightModeText;

    &:hover {
      color: $lightModeText;
    }
  }

  .Logo {
    width: 40px;
    margin-right: -2em;
  }

  .NavSection {
    display: flex;
    flex: 1 0;

    .external {
      opacity: 0.7;
      height: 1em;
      width: 1em;
      margin-left: 0.2em;
    }

    &:first-child {
      flex: 5 0;
      align-items: center;
    }

    .NavChild {
      margin-right: 4em;
      display:flex;
      

      .link-external {
        margin-top:5px;
      }
      &:last-child {
        margin: 0;
      }
    }

    @media (max-width: $mobileWidthBreakpoint) {
      .NavChild {
        margin-right: 1em;
      }
    }

    &:last-child {
      justify-content: flex-end;
    }
  }

  .NavDropdown {
    display: flex;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
  }

  .DropdownContainer {
    display: flex;
  }

  .DropdownMenu {
    margin-top: 0.5em;
  }
}
