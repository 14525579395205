.BackButton {
  display: flex;
  align-items: center;

  .BackIcon {
    margin-right: 1em;
  }

  a {
    margin-left: 0.3em;
  }
}

.Article {
  h1 {
    margin-bottom: 1em;
  }
  .ArticleSubheading {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;

    .ShareButtons {
      flex: 1 1 1;

      * {
        margin-left: 0.5em;

        &:first-child {
          margin: 0;
        }
      }
    }
  }
  img {
    margin-top: 2em;
    margin-bottom: 3em;
    width: 100%;
    border-radius: 3px;
    max-height: 600px;
    object-fit: cover;
  }
}
